import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { ContentCard } from 'components/ui/Card';
import Container from 'components/ui/Container';
import { ResponsiveGrid } from 'components/ui/Grid';
import TitleSection from 'components/ui/TitleSection';
import { graphql } from 'gatsby';
import { ImageSharpFluid } from 'helpers/definitions';
import React from 'react';

export enum ListType {
  CARD = 'Card',
}

export interface ContentListItem {
  title: string;
  description?: string;
  tags?: string[];
  date?: string;
  slug: string;
  cover?: {
    childImageSharp: {
      fluid: ImageSharpFluid;
    };
  };
}

interface ListPage {
  title: string;
  subtitle?: string;
  description: string;
  displayDescription: boolean;
}

interface ListPageProps {
  data: {
    indexPage: {
      frontmatter: ListPage;
    };
    pages: {
      edges: {
        node: {
          fields: {
            slug: string;
          };
          frontmatter: ContentListItem;
        };
      }[];
    };
  };
  pageContext: {
    collection: string;
    listType: string;
  };
}

export const query = graphql`
  query ListPage($collection: String!, $listType: String!) {
    indexPage: markdownRemark(
      fields: { collection: { eq: $collection } }
      fileAbsolutePath: { regex: "/.*index.md$/" }
      frontmatter: { listType: { eq: $listType } }
    ) {
      frontmatter {
        title
        description
        displayDescription
        listType
      }
    }
    pages: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: $collection } }
        fileAbsolutePath: { regex: "/^((?!index.md).)*$/" }
        frontmatter: { category: { ne: "dummy" } }
        published: { eq: true }
      }
      sort: { order: [ASC, DESC], fields: [fields___collection, frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            description
            title
            date(formatString: "MMM DD, YYYY")
            tags
            cover {
              childImageSharp {
                fluid(maxWidth: 800, quality: 60, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ListPage: React.FC<ListPageProps> = ({ data, pageContext }) => {
  const items = data.pages.edges.map((edge): ContentListItem => {
    return {
      ...edge.node.frontmatter,
      slug: edge.node.fields.slug,
    };
  });
  return (
    <Layout>
      <SEO
        title={data.indexPage.frontmatter.title}
        description={data.indexPage.frontmatter.description}
      />
      <Container section className="items-center justify-center">
        <TitleSection
          title={data.indexPage.frontmatter.title}
          subtitle={data.indexPage.frontmatter.subtitle}
          center
        />
        <ContentList items={items} listType={pageContext.listType as ListType} />
      </Container>
    </Layout>
  );
};

interface ListProps {
  items: ContentListItem[];
  listType: ListType;
}

export const ContentList: React.FC<ListProps> = (props) => {
  const renderListItem = (listType: ListType, props: ContentListItem) => {
    switch (listType) {
      case ListType.CARD: {
        return <ContentCard {...props} key={`${props.slug}`} />;
      }
    }
  };

  const renderListItems = (listType: ListType, items: ContentListItem[]) => {
    return items.map((item) => {
      return renderListItem(listType, item);
    });
  };

  switch (props.listType) {
    case ListType.CARD: {
      return <ResponsiveGrid>{renderListItems(props.listType, props.items)}</ResponsiveGrid>;
    }
  }
};

export default ListPage;
