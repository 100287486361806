import styled from 'styled-components/macro';

const ResponsiveGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 1rem;
`;

export default ResponsiveGrid;
