import React from 'react';
import { Card } from '.';

import * as Styled from './content-card.styles';

import Img from 'gatsby-image';
import { Tag } from '../Tag';
import { ContentListItem } from 'components/ListPage/list-page';
import { motion } from 'framer-motion';

import { Link } from 'gatsby';

const ContentCard: React.FC<ContentListItem & { className?: string }> = (props) => {
  return (
    <Styled.Post className={props.className}>
      <Link to={props.slug}>
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }} className="h-full">
          <Card className="h-full">
            {props.cover && (
              <Styled.Image>
                <Img fluid={props.cover.childImageSharp.fluid} alt={props.title} />
              </Styled.Image>
            )}
            <Styled.Content>
              {props.date && <Styled.Date>{props.date}</Styled.Date>}
              <Styled.Title>{props.title}</Styled.Title>
              {props.description && <Styled.Description>{props.description}</Styled.Description>}
            </Styled.Content>
            {props.tags && (
              <Styled.Tags>
                {props.tags.map((item) => (
                  <Tag key={item}>{item}</Tag>
                ))}
              </Styled.Tags>
            )}
          </Card>
        </motion.div>
      </Link>
    </Styled.Post>
  );
};

export default ContentCard;
