import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

export const Post = styled.div`
  ${tw`p-3`};
`;

export const Content = styled.div`
  color: ${(p) => p.theme.colors.text};
  ${tw`p-4`};
`;

export const Image = styled.figure`
  ${tw`w-full`};
`;

export const Title = styled.h3`
  ${tw`font-semibold mb-4`};
`;

export const Description = styled.p``;

export const Date = styled.h3`
  color: ${(p) => p.theme.colors.secondaryColor};
  ${tw`text-xs `};
`;

export const Tags = styled.div`
  display: inline-block;
  ${tw`p-4 pt-2 mt-auto`}
`;
